import React from "react"
import Nav from "./navigation"

const ThinHeader = () => {
  return (
    <header className="thin-header">
      <Nav />
    </header>
  )
}

export default ThinHeader
