import React from "react"
import { Link } from "gatsby"

import facebook from "../assets/images/facebook.svg"
import instagram from "../assets/images/instagram.svg"
import youtube from "../assets/images/youtube.svg"
import tiktok from "../assets/images/tiktok.svg"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__nav">
        <Link to="/privacy-policy/" className="footer__nav__item">
          Privacy Policy
        </Link>
        <a href="/terms-of-use/" className="footer__nav__item">
          Terms Of Use
        </a>
        <a href="/pets/" className="footer__nav__item">
          Pet Policy
        </a>
        <a href="mailto:info@nicesquirrel.com" className="footer__nav__item">
          Contact
        </a>
      </div>
      <div className="footer__social">
        <a
          href="https://www.instagram.com/nice.squirrel/"
          className="footer__icon"
        >
          <img src={instagram} alt="Squirrel on Instagram" />
        </a>
        <a
          href="https://www.facebook.com/Squirrel-108245677578860"
          className="footer__icon"
        >
          <img src={facebook} alt="Squirrel on Facebook" />
        </a>
        <a
          href="https://www.youtube.com/channel/UC35eidsvQ0O8R9depLrUhxA"
          className="footer__icon"
        >
          <img src={youtube} alt="Squirrel on YouTube" />
        </a>
        <a href="https://tiktok.com/@nice.squirrel" className="footer__icon">
          <img src={tiktok} alt="Squirrel on Tiktok" />
        </a>
      </div>
      <div className="footer__copyright">
        ©️ 2022 - 2024 GS Vanlife, Inc. All Rights Reserved, GS Vanlife, Inc. All
        Trademarks and Logos are owned by GS VanLife, INC.
      </div>
    </footer>
  )
}

export default Footer
