import React from "react"
import { Link } from "gatsby"

import SquirrelLogoWhite from "../assets/images/logo-squirrel-white.svg"

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = { menuActive: false }
    this.toggleMenuActive = this.toggleMenuActive.bind(this)
  }

  toggleMenuActive(props) {
    this.setState(prevState => ({
      menuActive: !prevState.menuActive,
    }))
  }

  render() {
    return (
      <nav className="nav-wrapper">
        <div className="nav-main">
          <Link to="/">
            <img
              src={SquirrelLogoWhite}
              alt="Squirrel Home"
              className="thin-header__logo"
            />
          </Link>
          <ul className="nav--inline">
            <li className="nav__item">
              <Link to="/our-motorhomes">Our Motorhomes</Link>
            </li>
            <li className="nav__item">
              <Link to="/on-demand">On Demand</Link>
            </li>
            <li className="nav__item">
              <Link to="/amenities">Amenities</Link>
            </li>
            <li className="nav__item">
              <Link to="/partner-with-squirrel">Partner With Squirrel</Link>
            </li>
            <li className="nav__item">
              <Link to="/culture">Culture</Link>
            </li>
          </ul>
          <button className="menu-button" onClick={this.toggleMenuActive}>
            <svg
              viewBox="0 0 30 20"
              width="30"
              height="20"
              stroke="#fff"
              fill="#fff"
            >
              <rect width="30" height="2" rx="1"></rect>
              <rect y="8" width="30" height="2" rx="1"></rect>
              <rect y="16" width="30" height="2" rx="1"></rect>
            </svg>
          </button>
        </div>
        <ul className={`nav ${this.state.menuActive ? "nav--active" : ""}`}>
          <li className="nav__item">
            <Link to="/our-motorhomes">Our Motorhomes</Link>
          </li>
          <li className="nav__item">  
            <Link to="/on-demand">On Demand</Link>
          </li>
          <li className="nav__item">
            <Link to="/amenities">Amenities</Link>
          </li>
          <li className="nav__item">
            <Link to="/partner-with-squirrel">Partner With Squirrel</Link>
          </li>
          <li className="nav__item">
            <Link to="/culture">Culture</Link>
          </li>
        </ul>
        {/* <div className="nav__button">
        <Link
          to="/videos/wingamm-interior-tour"
          state={{
            modal: true,
          }}
          className="nav__button__link"
        >
          Tour the Van
        </Link>
      </div> */}
      </nav>
    )
  }
}

export default Nav
