import React from "react"

import ThinHeader from "../components/thinHeader"
import Footer from "../components/footer"

export default function Layout({ children }) {
  return (
    <>
      <main>
        <ThinHeader />
        {children}
      </main>
      <Footer />
    </>
  )
}
